import '../styles/globals.css';
import 'tailwindcss/tailwind.css';
import {ThemeProvider} from 'next-themes';
import Layout from '@/components/layout';
import {Provider, useDispatch} from 'react-redux';
import {store} from '../redux/store';
import {login} from '../redux/slices/userSlice';
import {useRouter} from 'next/router';
import {MetaMaskProvider} from 'metamask-react';
import UserContext from '@/components/UserContext';
import React, {useEffect, useRef} from 'react';
import Cookies from 'js-cookie';
import NextNProgress from 'nextjs-progressbar';
import useSWR from 'swr';
import Script from 'next/script';
import CookieBanner from '@/components/cookie-banner/CookieBanner';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyB-gGEQ30Ke2BKoORSCCX34NiKB-2t-FeE",
	authDomain: "tevkilapp-71511.firebaseapp.com",
	projectId: "tevkilapp-71511",
	storageBucket: "tevkilapp-71511.appspot.com",
	messagingSenderId: "428649932956",
	appId: "1:428649932956:web:185ef41666f556542ba559",
	measurementId: "G-TL7QYL33ZH"
};

if (typeof window !== 'undefined') {
	require('bootstrap/dist/js/bootstrap');
}

function MyApp({Component, pageProps}) {
	const router = useRouter();
	const pid = router.asPath;
	const scrollRef = useRef({
		scrollPos: 0,
	});

	const app = initializeApp(firebaseConfig);
	const analytics = app.name && typeof window !== 'undefined' ? getAnalytics(app) : null;

	return (
		<>
			<link rel="stylesheet" href="https://unpkg.com/intro.js/introjs.css"/>
			<Script
				src={`https://www.googletagmanager.com/gtag/js?id=G-E9GJ1ZNW6V`}
				strategy="afterInteractive"
			/>
			<Script id="gooleAnalytics" strategy="afterInteractive">
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', 'G-E9GJ1ZNW6V');
				`}
			</Script>
			<Provider store={store}>
				<ThemeProvider enableSystem={true} attribute="class">
					<MetaMaskProvider>
						<UserContext.Provider value={{scrollRef: scrollRef}}>
							<NextNProgress
								color="#5B0888"
								startPosition={0.3}
								stopDelayMs={200}
								height={3}
								showOnShallow={true}
								options={{showSpinner: false}}
							/>
							{pid === '/auth' || pid === '/auth/register' || pid === '/auth/login' ? (
								<Component {...pageProps} />
							) : (
								<Layout>

									<Component {...pageProps} />
								</Layout>
							)}

							<CookieBanner/>
							<noscript>
								<iframe src="https://www.googletagmanager.com/ns.html?id=G-E9GJ1ZNW6V"
										  height="0"
										  width="0"
										  style={{ display: 'none', visibility: 'hidden' }}></iframe>
							</noscript>
						</UserContext.Provider>

					</MetaMaskProvider>
				</ThemeProvider>
			</Provider>
		</>
	);
}

export default MyApp;
